import Cleave from 'cleave.js/react'
import clsx from 'clsx'
import CrossIcon from 'modules/common/components/_icons/cross.svg'
import {
  ComponentProps,
  FocusEventHandler,
  forwardRef,
  HTMLProps,
  useState,
} from 'react'
import { Control, FieldError, useWatch } from 'react-hook-form'
import { Button } from '../../Button/Button'
import { HelperText } from '../../HelperText/HelperText'

export interface TextFieldProps extends HTMLProps<HTMLInputElement> {
  label?: string
  error?: FieldError
  control?: Control<any>
  leftDecorator?: React.ReactNode
  rightDecorator?: React.ReactNode
  helperText?: string
  className?: string
  inputClassName?: string
  clearable?: boolean
  reset?: () => void
  ref?: any
  maskOptions?: ComponentProps<typeof Cleave>['options']
  name: string
}

export const TextField = forwardRef<any, TextFieldProps>(
  (
    {
      label,
      error,
      control,
      leftDecorator,
      rightDecorator,
      helperText: helperTextFromProps,
      className,
      clearable,
      reset,
      inputClassName,
      maskOptions,
      type = 'text',
      ...inputProps
    },
    ref
  ) => {
    const value = useWatch({
      name: inputProps.name,
      control: control ? control : undefined,
    })

    const { disabled, id, onBlur, onChange, readOnly } = inputProps
    const [isFocused, setIsFocused] = useState(false)

    const labelPosition =
      isFocused || !(value == null || value === '') || readOnly
        ? 'floating'
        : 'static'

    const clear = () => {
      if (!reset) {
        throw new Error(
          'TextField: Cannot clear field if reset prop is missing.'
        )
      }
      reset()
    }

    const classNameInput = clsx([
      'border-0 ring-info-400 appearance-none rounded-lg w-full px-3 focus:outline-none focus:ring-0',
      (disabled || readOnly) &&
        'bg-info-100 text-info-300 ring-info-200 pointer-events-none cursor-default',
      error || isFocused ? 'ring-0' : 'ring-1',
      isFocused && !error && 'shadow-textfield-primary',
      error && 'shadow-textfield-danger',
      leftDecorator && 'pl-12',
      rightDecorator && 'pr-12',
      clearable && 'pr-12',
      clearable && rightDecorator && 'pr-16',
      inputClassName,
      label ? 'py-4' : 'py-3',
      label && labelPosition === 'floating' && 'pt-6 pb-2',
    ])

    const onFocus: FocusEventHandler<HTMLInputElement> = (e) => {
      setIsFocused(true)
      inputProps.onFocus?.(e)
    }

    const helperText = error?.message || helperTextFromProps

    return (
      <div className={clsx('relative', className)}>
        {maskOptions ? (
          <Cleave
            htmlRef={ref as any}
            options={maskOptions}
            className={classNameInput}
            value={value} // default value  https://github.com/nosir/cleave.js/blob/master/doc/reactjs-component-usage.md#how-to-pass-default-value
            {...inputProps}
            type={type}
            onFocus={onFocus}
            onChange={(e) => {
              e.target.value = e.target.rawValue
              onChange?.(e)
            }}
            onBlur={(e) => {
              setIsFocused(false)
              e.target.value = (e.target as any).rawValue
              onBlur?.(e)
            }}
          />
        ) : (
          <input
            type={type}
            ref={ref}
            className={classNameInput}
            {...inputProps}
            onFocus={onFocus}
            onBlur={(e) => {
              setIsFocused(false)
              onBlur?.(e)
            }}
          />
        )}
        {label && (
          <label
            htmlFor={id}
            className={clsx([
              'label leading-tighter pointer-events-none absolute left-3 mt-4 origin-top-left cursor-text text-base transition-transform',
              (disabled || readOnly) &&
                'pointer-events-none cursor-default text-info-300',
              isFocused && !error && 'text-primary',
              !!error && 'text-danger',
              labelPosition === 'floating'
                ? '-translate-y-2 scale-75 transform-gpu text-info-400'
                : 'text-info-600',
              leftDecorator && 'left-12',
            ])}
          >
            {label}
          </label>
        )}
        {leftDecorator && (
          <span
            className={clsx([
              'absolute left-3 flex h-6 w-6 items-center justify-center',
              label ? 'top-4' : 'top-3',
              (disabled || readOnly) && 'text-info-300',
              isFocused && !error && 'text-primary',
              !!error && 'text-danger',
            ])}
          >
            {leftDecorator}
          </span>
        )}
        {clearable && value && (
          <span
            className={clsx([
              'absolute flex h-6 w-6 items-center justify-center',
              label ? 'top-4' : 'top-3',
              rightDecorator ? 'right-9' : 'right-3',
              (disabled || readOnly) && 'text-info-300',
              label && 'top-4',
            ])}
          >
            {
              <Button
                variant="none"
                onClick={(event) => {
                  event.stopPropagation()
                  return clear()
                }}
                className="rounded hover:bg-info-100"
                icon={<CrossIcon className="h-6 w-6" />}
              />
            }
          </span>
        )}
        {rightDecorator && (
          <span
            className={clsx([
              'absolute right-3 flex h-6 w-6 items-center justify-center',
              label ? 'top-4' : 'top-3',
              (disabled || readOnly) && 'text-info-300',
              isFocused && !error && 'text-primary',
              !!error && 'text-danger',
              label && 'top-4',
            ])}
          >
            {rightDecorator}
          </span>
        )}
        {helperText && (
          <HelperText
            color={error?.message ? 'danger' : 'black'}
            text={helperText}
          />
        )}
      </div>
    )
  }
)
