import { formatAddress } from 'modules/common/lib/helpers/address'
import { CustomerField } from '../components/documents/creation/DocumentCreationFields/types'
import { Customer, CustomerFormProps } from '../types/customers'

export const mapCustomerToCustomerForm = (
  customer: Customer
): CustomerFormProps => {
  const {
    address: { street, city, zipCode, addressAdditional, country },
    name,
    VATNumber,
    email,
    phone,
    sirenOrSiret,
  } = customer

  return {
    name,
    VATNumber,
    email,
    phone,
    sirenOrSiret,
    street,
    city,
    zipCode,
    addressAdditional,
    country,
  }
}

export const mapCustomerFormToCustomer = (
  values: CustomerFormProps
): Partial<Customer> => {
  const { street, city, zipCode, addressAdditional, country, ...rest } = values

  return {
    ...rest,
    address: {
      city,
      street,
      zipCode,
      addressAdditional,
      country,
    },
  }
}

export const mapCustomerToSelectCustomerField = (
  customer: Customer
): CustomerField => {
  const { name, id, address } = customer

  return {
    label: name,
    value: id,
    secondaryLabel: formatAddress(address),
  }
}
